<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M17.1958 3.22656H9.43394
      C7.03177 3.22656 4.96094 5.17373 4.96094 7.57706V20.0756
      C4.96094 22.6142 6.89644 24.6384 9.43394 24.6384H18.7544
      C21.1578 24.6384 23.1049 22.4801 23.1049 20.0756V9.3819L17.1958 3.22656Z"
      :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.8906 3.21094V6.60477
      C16.8906 8.26144 18.2311 9.60544 19.8866 9.60894
      C21.4231 9.61244 22.9946 9.6136 23.1008 9.6066"
      :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.6715 18.1536H10.375" :stroke="color"
    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.2825 12.3724H10.3672" :stroke="color"
    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Paper',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
